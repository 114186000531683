import { Router } from '@angular/router';
import { Login } from 'src/app/auth/login/login.model';
import { Injectable } from '@angular/core';
import { Observable, mergeMap } from 'rxjs';
import { Account } from 'src/app/core/auth/account.model';
import { AccountService } from 'src/app/core/auth/account.service';
import { AuthServerProvider } from 'src/app/core/auth/auth-jwt.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private accountService: AccountService, private authServerProvider: AuthServerProvider, private router: Router) {}

  login(credentials: Login): Observable<Account | null> {
    return this.authServerProvider.login(credentials).pipe(mergeMap(() => this.accountService.identity(true)));
  }

  logout(): void {
    this.authServerProvider.logout().subscribe({
      complete: () => {
        this.accountService.authenticate(null);
        this.router.navigate(['']).then(() => {
          window.location.reload();
        });
      },
    });
  }
}
