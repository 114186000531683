// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  test: false,
  // authBaseUrl: "http://localhost:5054",
  // userBaseUrl: "http://localhost:5055",
  // requestBaseUrl: "http://localhost:5056",
  // resourcesBaseUrl: "http://localhost:5057",
  authBaseUrl: 'https://apidev.ahyeha.com/authentication',
  userBaseUrl: 'https://apidev.ahyeha.com/usermanagement',
  requestBaseUrl: 'https://apidev.ahyeha.com/request-manager',
  resourcesBaseUrl: 'https://apidev.ahyeha.com/resourcesmanagement',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
