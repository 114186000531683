import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AccountService } from 'src/app/core/auth/account.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(public accountService: AccountService, public router: Router, public route: ActivatedRoute) {}
  canActivate(): Observable<boolean> {
    return this.accountService.identity().pipe(
      map(() => {
          if (!this.accountService.isAuthenticated()) {
            this.router.navigate(['auth/login']);
            return false;
          }
          return true;
      })
    )
  }
}
