import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AccountService } from './account.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuardService {

  constructor(public accountService: AccountService, public router: Router) {}
  canActivate(): Observable<boolean> {
    return this.accountService.identity().pipe(
      map(() => {
          if (this.accountService.isAuthenticated()) {
            this.router.navigate(['dashboard']);
            return false;
          }
          return true;
      })
    )
  }
}
